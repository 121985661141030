const posts = [
    {
        imgSrc: "assets/vanhoy_post_thumbnail.png",
        title: "Advice by Prof. Vanhoy",
        date: "April 22, 2021",
        blurb: `A convo with Prof. Garrett Vanhoy on career advice for CS grads.`,
        content: 
        <span>
            OPHIR<br/>
            I wanted to ask about the process of choosing a company to work for. How does one go about doing that? what factors should you consider? How should the size of the company affect my decision?
            <br/><br/>
            VANHOY<br/>
            So in terms of navigating the space of potential jobs: the first dimension you mentioned is the size of the company, another one is role (and what level of expertise, i.e. seniority). So full disclosure, I haven't done hardcore research into the differences between big and small companies but I can tell you what I know and based on what I've heard and on my intuition. 
            <br/><br/>
            OPHIR <br/>
            That's fine, I'll take it with a grain of salt.
            <br/><br/>
            VANHOY<br/>
            So for small companies, it's kind of a high-risk high-reward situation, but it can easily be an investment of a lot of time, because start-ups or young companies, if they're successful if you've invested any stocks, you may be able to sell them for a lot of money after a few year. But if the company goes under, the stocks can plunge. For startups, the dream is to be bought by the big companies. 
            <br/><br/>
            Another aspect of these companies, which can be good or bad, is you have to wear a lot of hats, and sometimes you may even do something like HR for such companies, cuz they can't afford a unique person for every role. In a big company, however, you'll have a very well-defined role. 
            <br/><br/>
            OPHIR <br/>
            Do you see this is as an advantage or a disadvantage?
            <br/><br/>
            VANHOY<br/>
            I think when it comes to your early career, learning a lot is gonna be to your advantage. I think that in a big company you can navigate your job such that you learn a lot, you just have to be more calculated, whereas in a small company it's just natural, either you learn a lot or they can't keep you around. So that's the risk. 
            <br/><br/>
            In terms of medium-size companies, for the most part I don't see a particular advantage, it's basically a mixture of benefits of big and small companies.
            <br/><br/>
            For a big company, the company is usually not gonna go under, you won't be at the risk of losing a job, and the way you "win" so-to-speak in a big company is you figure out where the opportunities are in that company and you move towards them. In a small company, it's just so dynamic, the whole company itself has to move because it's so small. 
            <br/><br/>
            Big companies usually have well defined paths for employees to move around: you can go to your boss or HR person and ask "how do I get to senior level software developer?" and you can negotiate those kinds of things. In a medium to big company, you can even make up a position and ask people what they think about you getting that title in a couple years.
            <br/><br/>
            OPHIR<br/>
            and when you say move, do you only mean moving up? or also between departments?
            <br/><br/>
            VANHOY<br/>
            Well, sometimes lateral [movement] can be good too. Sometimes different departments in those big companies act like small companies and they sometimes have very different cultures, so you might wanna move between departments.
            <br/><br/>
            OPHIR<br/>
            and when you say opportunities you mean opportunities in general? not just in terms of what product is the most successful product in a given company?
            <br/><br/>
            VANHOY<br/>
            Yeah, so for you, in early career, it's about: what am I gonna learn that will increase my value as an employee? that's the biggest thing, but also which department would fit my lifestyle the most.
            So in big companies there are so many spots usually and it's just a matter of negotiating and making your way to those spots, but the smaller the company gets - the more you have to make these spots for yourself.
            <br/><br/>
            OPHIR<br/>
            Do you have an idea of whether it's better for a recent grad to start at a small company vs. a big company?
            <br/><br/>
            VANHOY<br/>
            I think too small of a startup is too much of a risk. If you want to be in, like a 1 to 10 people company,  you have to have a fierce mentality that you're gonna make it work, like you've gotta have this "never give up for the next 5-10 years" kind of mentality, all of the successful startups have this mentality, at least at the core, and if you join that early, you're gonna need this mentality of "I'm gonna sacrifice everything for the next 5 years, I don't care, I'm gonna make this company work". And if you have this mentality, that's where you should be, and if you really like it, that's where you should be. But I think that the safer startups are ones with 30-50 people, where they made a few rounds of funding, they have a market, they've established relationships, the core is there, they're gonna have the sleepless nights and you're just gonna have, maybe, less sleep at night. They're gonna be taking all the flak while the company moves forward.
            <br/><br/>
            OPHIR<br/>
            Do you think that because they need people that can wear many hats so-to-speak that it would be harder to get a job ? i.e. cause startups may need someone who already has a lot of knowledge.
            <br/><br/>
            VANHOY<br/>
            I do. And if you meet a small business that isn't picky, that should be a red flag.
            <br/><br/>
            OPHIR<br/>
            So is it gonna be harder for me to get hired, as a recent grad who probably doesn't know a lot?
            <br/><br/>
            VANHOY<br/>
            Yeah, that's a good point, it probably will be. But there's a reason why they might want to hire you, one is because they have money from a VC and they just need to spend it. They have all these tasks that they don't need their most seasoned developers to do, and they'd be more than happy to hire fresh graduates to do those things. It happens a lot. They want to scale up, and to do that they need to hire a lot of fresh people but there's a time for it. They need to have the money and a plan to be able to do that.
            <br/><br/>
            OPHIR<br/>
            Is there a way or a trick to recognize if that's what's going on?
            <br/><br/>
            VANHOY<br/>
            I don't know, that's pretty hard, and I haven't been in that culture enough to know, and I've never interviewed at a startup. I recently interviewed for a 30-person company who's been around for ~10 years, but they do research, not so much pure software, and so they're on a slower trajectory, they wouldn't fall out of existence in one year, whereas some software companies can do that.
            <br/><br/>
            Yeah, I think startups are risky, and that big companies are good to start with.
            <br/><br/>
            OPHIR <br/>
            Yeah, it's good to have a big name on your resume.
            <br/><br/>
            VANHOY<br/>
            Yeah, and a lot of them, especially Amazon, they'll let you work your butt off if you're willing to learn and grow, and go on to new experiences, is what I hear. If you want to learn a new skill and you want to move up in the company, they'll let you do that, but then if you wanna have a better worklife balance, depending on the position they'll allow it as much as makes sense.
            <br/><br/>
            OPHIR<br/>
            Switching gears here, could you talk a bit about what the different areas of computer science are? e.g. gaming, cybersecurity, data science, etc. And then, do you have any tips for how to know which area a person might prefer? e.g. if you liked projects more vs theory you should do this and not that, that king of thing.
            <br/><br/>
            VANHOY<br/>
            So in terms of cybersecurity, that's either a very researchy topic, or it's more of an IT role, and IT is not usually that exciting for CS students. I don't mean to put it down but it's just considered a bit more of a blue collar work among CS people. Eventually it comes down to getting certified continuously, knowing the rules, and following the rules, there's not as much development there. 
            <br/><br/>
            I wanna say, there are a lot of software engineering positions, and a lot of them are kind of generic but if I was to separate them into different kinds: gaming is grueling, I have not heard good things from anyone about gaming from anyone, anywhere on the internet, about that industry for computer science. I think a lotta ppl go into it, it's a crowded market, cuz it actually doesnt require a lotta CS skills to do it well, it's not that hard from a programming point of view, and therefore the treatment there is not that great. I think the only exception are companies like EA or Blizzard where they're kind of at the top of the development food chain where they get to do actual engineering, but I think most of it is just an army of basic programmers. That's what I've heard. Maybe you'll enjoy it if you're really into video games, but even then it's not that you'll play video games at work much as a developer. 
            <br/><br/>
            For web or mobile development, I'd put those at the same category, they're also kind of like the gaming industry but just not as bad, because there's a lot of different frameworks and... But hiring a web developer is kind of like hiring a contractor, you just need someone who knows it well enough. The problem is it's just not hard to do web development or mobile apps well, so it becomes a crowded market very quickly, and you might working on apps or websites you're totally uninterested in, you might end up working for a service company and which is hired by other companies to build websites for them. And I think that's not very exciting work, if you wanna learn new things, cause your company will probably use the same tech stack for 10 years, so you probably won't learn new tech that much.
            <br/><br/>
            OPHIR<br/>
            Well who says I'll stay there for ten years?
            <br/><br/>
            VANHOY<br/>
            Well that's another point, you can move between companies. But I know this company called Accenture, and I think they do cool things. They are one or two levels above mobile/web dev in the sense that they're considered more capable of developing more general apps and not just vanilla mobile/web apps. Their customers are Amazon/Google, etc. They have good devs, they make new things and learn new technologies, and they need people who can learn on their own. And I think that's something a master's degree can help you with, because a master's degree, whether you've done research or not, tells your employer I know how to learn complicated things, and I can come to understand it and put it to practice, that's what it says, and they pay well, and give a lot of flexibility. Companies like that just hire good engineers, that can tackle more vague, more abstract problems. Generally the higher up you go in terms of hardcore engineering, the more vague the problems become (e.g. how would you design the infrastructure for the internet). I didn't ask people at Accenture about whiteboard (leetcode problems) , but it seems there wasn't that much of an emphasis on that. It's probably more like questions that test for engineering thinking, e.g. like how would you design a bridge that would cross the entire ocean, and there's no right answer usually, they just wanna see how you break a big ambiguous problem down into smaller more manageable and less ambiguous subproblems and solve it that way.
            <br/><br/>
            OPHIR <br/>
            Well I bet it's harder to practice for questions like these, but I assume there's online resources for that too.
            <br/><br/>
            VANHOY <br/>
            You can, yeah. Microsoft is famous for these kind of question, and there are some books for that. But it boils down to thinking like an engineer and being able to verbalize your thinking. Elon Musk would ask you to design his factory, but I think that is a little too much.
            <br/><br/>
            OPHIR<br/>
            Do you have any tips for choosing a software engineering job?
            <br/><br/>
            VANHOY<br/>
            Be strategic, and don't apply to every job, becuase it's a draining process. It's much better to go in with a rough idea of what's a good first job, kind of be happy even if it's not the best job, and just get your foot in the door. You can roll with it after that. But if you're really unhappy, don't settle, and always be looking for the next step. Really successful people are always thinking about what their next step is, 2-3 years from now, and it's good to do that so you don't get stuck. Apply for a reasonable amount of the things you're actually genuinely interested in, not just everything that comes your way.
            <br/><br/>
            OPHIR<br/>
            Okay, and in terms of how to get the job, any tips? 
            <br/><br/>
            VANHOY<br/>
            Submitting resumes, following up, showing interest… I have interviewed candidates, and I think the thing that made the difference for them is a mixture of technical ability and personability, as in, would I want to work with this person.
            <br/><br/>
            I think a very good quality of engineers, especially entry level ones is being able to admit you don't know everything. 
            <br/><br/>
            OPHIR<br/>
            Are you kidding? that seems like the easiest thing to do.
            <br/>
            VANHOY<br/><br/>
            Exactly, but I have seen candidates that are more interested in showing what they do know, which is fine, but it can be hard to trust them at some point. Basically, if someone admits they don't know something, it helps you trust them what that person says from that point on, and helps you understand they're not someone who thinks they can do anything on day 1, no one can do that. It's about knowing where you are and where you wanna go, and then the employer can work around that. A lot of engineers I've met are very sensitive about saying what they do and do not know.
            <br/>
            OPHIR<br/><br/>
            Maybe part of the problem is that on the job description they write 4 years experience with this, 5 years of that… and it's pretty ridiculous. I mean, I just graduated, I don't know much.
            <br/>
            VANHOY<br/><br/>
            Well yeah, on your resume you're allowed to exaggerate a little bit, but in person it shouldn't come across that way.
            <br/><br/>
            OPHIR<br/>
            Is it okay to apply for jobs that I don't really have the all of the qualifications for according to the job description? put it differently, 
            <br/><br/>
            VANHOY<br/>
            Well yeah, as long as it doesn't cost you a lot of time, as in, if it's only a matter of submitting your resume, because if they call you in for an interview, then they're interested.
            <br/><br/>
            OPHIR<br/>
            So in other words, should I take these qualifications seriously?
            <br/><br/>
            VANHOY<br/>
            Oh no, don't.
            <br/><br/>
            OPHIR <br/>
            I think companies usually post a description of what the ideal candidate is, but that doesn't mean they won't settle for less. And often no one really fits that description.
            <br/><br/>
            VANHOY<br/>
            That's right. I think it's reasonable to ignore some of what they say.
            <br/><br/>
            OPHIR<br/>
            Awesome, this has been really helpful, thank you so much for your time, I really appreciate it.
        </span>,
        modalId: "vanhoy-talk"
    },
    {
        imgSrc: "assets/jerry_post_thumbnail.jpg",
        title: "Landing a job at Facebook",
        date: "April 23, 2021",
        blurb: `A convo with my friend Jerry Qian on how he landed a job at Facebook.`,
        content: 
        <span>
            OPHIR<br/>
            Alright, so I'm sure you've done a bit of applying to jobs online, but how did you get into Facebook?
            <br/><br/>
            JERRY<br/>
            So when applying to the big companies, like the ones that are hard to get into, if you only apply online, your chances are much lower. 
            It's possible to get in, if you have a great resume they might give you a chance, but the thing is, so many people apply, and they can't interview everyone,
            so a much more reliable way is -- to get a referral. You get a current employee to write a referral for you and then that basically puts in the application for you.
            And they reach out to you.
            <br/><br/>
            OPHIR<br/>
            So go to Mountain View, California, and try to stalk Facebook employees?
            <br/><br/>
            JERRY<br/>
            Well if you have a friend that works there, like me once I start working there, then you can ask them for a referral. It's free for the employee, 
            and if you get an offer and you accept it, the employee gets a bonus of 5,000 bucks! It's like a finder's fee, so there's motivation for employees to refer you.
            <br/><br/>
            In my case, my neighbor happened to work for Facebook, so things worked out pretty well.
            <br/><br/>
            OPHIR<br/>
            SO I have this friend who got a BS and an MS in computer science at UMD, like me, and then he moved to Israel. He applied for a lot of jobs and put in a lot of time,
            but the only thing that worked for him was using his network, using personal connections, so basically referrals. So his advice was basically get a referral somehow, 
            find someone you know or someone your friend or relative knows and try to get a referral from them.
            <br/><br/>
            So in terms of the coding interviews, did you do the usual grind of LeetCode questions and all that?
            <br/><br/>
            JERRY<br/>
            Yeah, so, I did some LeetCode, I didn't do as many problems as some of the people on the internet, ("I did 500 problems on LeetCode and I still didn't get in").
            So it's not all LeetCode, you should know your basics, you should be able to solve problems in a reasonable amount of time, but also,
            you need to do a lot of mock interviews because if you're just doing LeetCode questions you're not explaining your thought process, you're just typing out a solution.
            <br/><br/>
            Solving problems is one aspect, but the best thing you can do is find another person who's studying for interviews that can practice with you and give you feedback on how you explained your solution. So get used to talking while you're thinking. That worked out for me.
            <br/><br/>
            OPHIR<br/>
            Awesome, so you had a partner to practice with?
            <br/><br/>
            JERRY<br/>
            My brother gave me a lot of mock interviews, and I also did some with my roommates. I did practice last year and got an internship with them.
            <br/><br/>
            OPHIR<br/>
            So you had to pass a technical interview with Facebook? what was that like?
            <br/><br/>
            JERRY<br/>
            I had to do two 45-minute interviews with an engineer from Facebook.
            <br/><br/>
            OPHIR<br/>
            And is it the same kind of problems we see on LeetCode? arrays and strings and graphs and trees and all?
            <br/><br/>
            JERRY<br/>
            Yeah, basically. I also did a couple of Google interviews, and Amazon, I think they do it a little bit differently. 
            In terms of my experience, FB asked general algorithmic questions, while at Google the engineers come up with whatever questions they want, 
            whatever they think will help them understand how you think, so I got very different questions. They were still algorithmic, but for example, one was themed in Android, and another one was sort of a visual question, but you don't need to learn these kinds of things. And Amazon makes you take a 3-4 hour online assessment and if you pass that they give you an interview.
            <br/><br/>
            OPHIR<br/>
            Interesting. I gotta say the interview prep process is more or less what I expected, but it's definitely good to hear about someone who did these things
            and had it work for him. Did you do anything to prepare for behavioral questions?
            <br/><br/>
            JERRY<br/>
            Not really (smiling). I don't really know how you prepare for that, I think it's just common sense.
            <br/><br/>
            OPHIR<br/>
            What about preparing answers for questions like "tell me about a time you had a conflict with a teammate"?
            <br/><br/>
            JERRY<br/>
            So for the big companies like Google, Facebook, Amazon, they don't really ask these questions. Their behavioral questions were just multiple choice.
            But when I interviewed at Capital One, for example, they did ask a behavioral question, and I didn't do as well, that was hard. Some people excel at that, but I don't.
            <br/><br/>
            OPHIR<br/>
            I'm not sure how they even score you on your answer. Whatever.
            <br/><br/>
            JERRY<br/>
            One thing to keep in mind is you wanna be able to solve these problems fast and type fast, since you won't have a lot of time for them. Sometimes they give you two problems, 
            sometimes they give you just one, and sometimes they give you an easy one and then extend it to a harder one.
            <br/><br/>
            It's not enough to know the logic of something, and then kind of sort of be able to code it but do it slowly and awkwardly, you need to be able to just type it out quickly.
            At least the fundamentals, like for loops, but also things like binary search.
            <br/><br/>
            OPHIR<br/>
            So should I time myself when I practice?
            <br/><br/>
            JERRY<br/>
            You don't really need to time yourself, just get more practice and get faster with time. After you do a lot of problems, you'll start to identify the few approaches that each 
            category of problems has, and you'll get good at using these approaches.
            <br/><br/>
            OPHIR<br/>
            So after you got the internship at FB, did they just immediately give you an offer?
            <br/><br/>
            JERRY<br/>
            For FB, you don't have to re-interview, and depending on how well you performed, they just give you an offer, which is pretty handy. I know that at Google, their hiring process 
            is not as straightforward, they re-interview, and there's also the team matching phase and you can sit in that pool for a long time before you get matched, and sometimes you don't get matched.
        </span>,
        modalId: "jerry-convo"
    }
]

export { 
    posts
}


