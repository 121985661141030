const softwareProjects = [
    {
        imgSrc: "assets/youmixit_thumbnail.png",
        title: "YouMixIt",
        date: "I3DA 2021 International Conference",
        blurb: `Enhancing music listening with deep source separation and spatial audio.`,
        description: 
        <span>
            Click on <span className="btn-secondary" disabled>Project Website</span> below to see the website I developed 
            for my final master's project.<br/>The password is: <span className="btn-secondary" disabled>i3da2021</span><br/><br/>
            Modern streaming services dominate the way people
            consume music. While immediate access to music is certainly
            convenient, such services do not provide users with much control
            over their listening experience, other than simple volume settings
            or more rarely via equalization. In this work, we enhance the
            music listening experience by combining deep musical source
            separation with online binaural spatial audio remixing of the
            separated sources. We introduce YouMixIt, an end-to-end web
            application that allows users to separate music downloaded from
            YouTube into four different audio stems (vocals, bass, drums, and
            accompaniment) and interactively manipulate the 3D position
            and volume settings of each stem within the simulated listening
            space. Our interface introduces novel degrees of control allowing
            users to create personalized musical mixtures.
        </span>,
        links: [{ title: 'Project Website', url: 'https://youmixit.umiacs.umd.edu/' },
                { title: 'Published Paper', url: 'https://doi.org/10.1109/I3DA48870.2021.9610975' },
                { title: 'Video Presentation', url: 'https://youtu.be/8npRfB7msWc' }],
        modalId: "youmixit"
    },
    {
        imgSrc:"assets/internship2019_thumbnail.png",
        title:"Symptom Networks",
        date:"Summer '19 Internship at NIH",
        blurb:`Infering pivotal symptoms in cancer 
        patients with graph algorithms.`,
        description:<span>For my summer '19 internship at the NIH, I
        implemented a web application in R that enables analysis
        and exploration of cancer symptomology data for
        outcomes researchers and clinicians with little
        bioinformatics expertise. The computational
        approach builds on a network-based approach, first
        described by Henry et. al. We demonstrate the
        functionality and features in a case study on data from  
        the <a href="https://academic.oup.com/nop/article/8/4/460/6220143?login=true">
        Brain Tumor Natural History Study of patient symptoms</a>.</span>,
        links:[{ title: 'Project Poster', url: 'assets/internship19_poster.pdf' },
               { title: <span>Code <i className="bi bi-github"></i></span> , url: 'https://github.com/ophirgal/concordNets' }],
        modalId: "internship2019"
    },
    {
        imgSrc:"assets/internship2018_thumbnail.png",
        title:"Remission Prediction",
        date:"Summer '18 Internship at NIH",
        blurb:`Predicting remission in cancer patients using machine learning.`,
        description:`For my summer '18 internship at the NIH, 
        I used machine learning algorithms to predict complete remission 
        in AML patients who have received induction therapy, 
        based on their pre-treatment gene expression. 
        We did a Principal Component Analysis (PCA) to get a
        sense of the data, its outliers, and what methods may work well
        with the data. We performed hyperparameter tuning, 
        statistical significance tests to determine which genes 
        are differentially expressed in patients who achieved complete 
        remissions, and embedded cross validations for feature selection.`,
        links:[{ title: 'Project Poster', url: 'assets/internship18_poster.pdf' },
               { title: 'Published Paper', url: 'https://doi.org/10.1177/1176935119835544' },
               { title: <span>Code <i className="bi bi-github"></i></span> , url: 'https://github.com/ophirgal/Predict-CR-AML' }],
        modalId:"internship2018"
    },
    {
        imgSrc:"assets/sm_scraper_thumbnail.png",
        title:"Social Media Scraper",
        date:"March – April 2021",
        blurb:`Scraping and visualizing social media content with an interactive dashboard.`,
        description:<span>
        Final project for the class Interactive Analysis Systems (CMSC828D).
        <br/><br/>
        With the prevalence of police misconduct, users of social media platforms have been producing
        increasing amounts of online content to help raise awareness of such events as well as voice their opinion. The public availability of
        such data poses an opportunity for meaningful analysis for organizations wanting to make a positive impact. 
        <br/><br/>
        In this work, we develop a software package, sm-scraper, 
        which is designed to scrape text from social media websites, 
        filters and parses it into a database, and visualizes the
        data through an interactive search engine. The system is designed for the Full Disclosure Project, which aims to identify instances of
        police misconduct. In consideration of our client, we engineered our application to be easily extendable, and placed emphasis on
        identifying misconduct-related posts with their corresponding jurisdictions. To complete the project, we overcame various technical
        challenges associated with the scraper API (specifically, Reddit), lemmatized keyword search, jurisdiction identification from named
        entities, post relevance classification, and dashboard design.</span>,
        links:[{ title: 'Project Report', url: 'assets/sm_scraper_paper.pdf' },
               { title: <span>Code <i className="bi bi-github"></i></span> , url: 'https://github.com/ophirgal/sm-scraper' }],
        modalId:"sm-scraper"
    },
    {
        imgSrc:"assets/robust_lang_models_thumbnail.png",
        title:"Robust Language Models",
        date:"March – April 2021",
        blurb:`Robustifying language models for privacy policy classification.`,
        description:<span>Final project for the class Privacy and Ethics in data 
        Management Systems (CMSC828E).   
        <br/><br/>
        The explosion of user-generated data over the last
        few decades has led to an influx of privacy policies, legal
        documents created to disclose the various means by which
        modern-day web services utilize and capitalize on user data.
        However, it is widely known that most users do not read these
        lengthy documents and are thus far more likely to use question
        answering systems allowing them to ask about the privacy aspects
        they care about. This has motivated the development of tools for
        summarizing, segmenting, and classifying privacy policies according
        to classes inspired by articles in recent privacy regulations
        in the European Union (EU) and California. 
        <br/><br/>
        In this work, we develop two fine-tuned language models using the transformerbased
        model DistilBERT for classifying privacy-related questions
        and segments of privacy policies into different categories related
        to data handling. We then automatically generate adversarial
        data to test our models’ robustness and retrain the models with
        the adversarial examples to see how their resiliency increases.
        While our privacy questions model improved significantly, our
        privacy policy model did not improve much from this method,
        showing the limitations of model improvement using adversarial
        examples and language models.</span>,
        links:[{ title: 'Project Report', url: 'assets/robust_lang_models.pdf' },
               { title: <span>Code <i className="bi bi-github"></i></span> , url: 'https://github.com/ophirgal/robust-lang-models' }],
        modalId:"robust-lang-models"
    },
    {
        imgSrc:"assets/ssl_descent_thumbnail.png",
        title:"Double Descent in SSL",
        date:"October – November 2020",
        blurb:`Showing “double descent” occurs in deep semi-supervised networks.`,
        description:
        <span>
        Code for the final project for the graduate class Foundations of Deep Learning (CMSC828W).   
        <br/><br/>
        Team members: Ophir Gal, Amartya Banerjee, Kaumil Patel, Saadiq Shaik, Seungwon Oh.   
        <br/><br/>
        Semi-Supervised Learning (SSL) is a branch of
        machine learning utilizing both labeled and unlabeled data during training. SSL methods aim
        to address the problem of limited labeled data
        in the real-world. Such methods are especially
        important when training deep learning models,
        which are highly dependent on large amounts of
        data. Recent findings have shown that increasing
        model complexity tends to display a double descent behavior where, contrary to traditional perspective in machine learning, increasing model
        complexity improves generalization (Belkin et al.,
        2019; Nakkiran et al., 2019).
        <br/><br/>
        This work primarily
        focuses on two types of SSL methods, namely
        pseudo-labeling and Virtual Adversarial Training (VAT). We first reproduce the double descent
        behavior in deep neural networks on standard
        datasets (MNIST and CIFAR-10). We then investigate whether this behavior holds to these two
        SSL methods. Lastly, we show the effect of changing the ratio of labeled to unlabeled samples on
        the double descent curve.</span>,
        links:[{ title: 'Project Report', url: 'assets/ssl_descent.pdf' },
               { title: <span>Code <i className="bi bi-github"></i></span> , url: 'https://github.com/ophirgal/ssl-descent' }],
        modalId:"ssl-descent"
    },
    {
        imgSrc:"assets/artapp_thumbnail.png",
        title:"ArtApp",
        date:"November 2020",
        blurb:`An Android application for collaborative art.`,
        description:
        <span>
        Final project for my Android development class (CMSC436).
        <br/><br/>
        Application Concept:<br/>
        A collaborative art app which allows users to draw on their screen 
        while simultaneously seeing the drawings of others.
        <br/><br/>
        Key Functionality:<br/>
        When a user lifts their finger after drawing on the canvas,
         some representation of the drawing (last scribble) is sent to the
         Firebase database. Any other user currently on the app gets notified
         and updates their canvas/view to display the drawings of other users.
        </span>,
        links:[{ title: 'Project Demo (Video)', url: 'https://drive.google.com/file/d/1hYJyaX0HwwHO0D5_qlyWBhl2DiZ38sji/view?usp=sharing' },
               { title: <span>Code <i className="bi bi-github"></i></span> , url: 'https://github.com/ophirgal/artapp' }],
        modalId:"artapp"
    }
]

const musicProjects = [
    {
        imgSrc:"assets/use_me_thumbnail.png",
        title:"Use Me (Cover)",
        date:"Summer 2016 at BPM College of Music",
        blurb:<span>This was my final project for the Sound &#38; Production course
        at BPM.</span>,
        description:<span>
            For my final sound &#38; production project at <a href="https://www.bpmcollege.com/">BPM College of Music</a> I chose to cover Bill Withers' "Use Me".
            Enjoy :)<br /><br />
            Mixing &#38; Drums: Ophir Gal<br />
            Recording: Ophir Gal &#38; Dor Agaian<br />
            Vocals &#38; Saxophone: Dor Agian<br />
            Keyboard: Tomer Ben Yaakov<br />
            Bass Guitar: Shaked Dar<br />
            Electric Guitar: Almog Ben Galim
        </span>,
        links:[{ title: 'YouTube Video', url: 'https://youtu.be/fjfCATMS1ZM' }],
        modalId:"use-me"
    },
    {
        imgSrc:"assets/simaney.png",
        title:"Simaney Hazman",
        date:   "Summer 2016 at BPM College of Music",
        blurb:<span>This was my final project for the Music Editing course
        at BPM.</span>,
        description:<span>
            For my final music editing project at <a href="https://www.bpmcollege.com/">BPM College of Music</a> my sister and I recorded an original song.
            Enjoy :)<br /><br />
            Lyrics, music, guitar, and vocals: Shani Gal<br />
            Backing Vocals: Shachar Ruppin<br />
            Bass : Tommy Simmons<br />
            Drums, recording, producing, and mixing: Ophir Gal<br />
        </span>,
        links:[{ title: 'YouTube Video', url: 'https://youtu.be/TDqEpNhnBjY' }],
        modalId:"simaney"
    }
]

export { 
    softwareProjects,
    musicProjects
}


