import React from 'react'
import { functions } from "./firebase"
import { httpsCallable } from 'firebase/functions'

function ContactForm(props) {
    React.useEffect(() => {
        document.querySelector('#message-form').addEventListener('submit', props.send)
    }, [])
    return (
        <form id="message-form" className="w-100">
            <h1 className="text-center">Let's Talk!</h1>
            <div className="form-group mb-2 fs-4">
                <label htmlFor="email-input" className="mb-1">Your Email</label>
                <input type="email" className="form-control fs-5" id="email-input" placeholder="name@example.com" required />
            </div>
            <div className="form-group mb-2 fs-4">
                <label htmlFor="subject-input" className="mb-1">Subject</label>
                <input type="text" className="form-control fs-5" id="subject-input" placeholder="Let's Work Together! - Fellow Human" required />
            </div>
            <div className="form-group my-2 fs-4">
                <label htmlFor="message-input" className="mb-1">Message</label>
                <textarea className="form-control fs-5" id="message-input" placeholder="Hey! ..." rows="3" required></textarea>
            </div>
            <div className="d-flex align-items-center justify-content-center">
                <input type="submit" className="w-50 btn btn-outline-light my-2 fs-4" value="Send" />
            </div>
        </form>
    )
}

export default function Contact() {
    React.useEffect(() => window.scrollTo(0, 0), []) // go to top on first load
    const [loading, setLoading] = React.useState(false)
    const [feedbackTitle, setfeedbackTitle] = React.useState('Thanks!')
    const [feedbackBody, setfeedbackBody] = React.useState('Your message was sent.')
    var sendMessage = httpsCallable(functions, 'sendMessage')

    function send(event) {
        event.preventDefault()

        let subject = document.querySelector('#subject-input').value
        let text = `Sender's Email: ${document.querySelector('#email-input').value}\n`
            + '(sent via ophirgal.com)\n\n'
            + document.querySelector('#message-input').value

        setLoading(true)

        sendMessage({ subject: subject, text: text })
            .then(() => {
                setLoading(false)
                setfeedbackTitle('Thanks!')
                setfeedbackBody('Your message was sent.')
            }).catch(error => {
                setLoading(false)
                setfeedbackTitle('Uh-oh!')
                setfeedbackBody('Something went wrong.')
                console.log(error)
            }).finally(() => {
                setLoading(false)
                document.querySelector('#modal-trigger').click()
            })
    }

    return (
        <>
            {/* Contact Me Section */}
            <section className="bg-dark text-light p-lg-0 text-start">
                <div className="container px-5">
                    <div className="row pb-5">
                        <div className="col d-flex align-items-center justify-content-center">
                            {loading
                                ?
                                <div className="spinner-border text-light" role="status" />
                                :
                                <ContactForm send={send} />
                            }
                        </div>
                        <div className="col d-none d-sm-block">
                            <div className="d-flex h-100 align-items-center justify-content-center">
                                <img className="img-fluid w-75" src="assets/mailbox.svg" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Post-send Feedback Modal */}
            <div className="modal fade" id="send-modal" tabIndex="-1" aria-labelledby="sendLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg my-2">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="sendLabel">{feedbackTitle}</h5>
                            <button type="button" className="btn-close btn-close-white shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mb-0">
                            <p className="pe-2 mb-0">
                                {feedbackBody}
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* invisible modal trigger button */}
            <button id="modal-trigger" className="d-none" data-bs-toggle="modal" data-bs-target="#send-modal" />
        </>
    )
}

