import React from 'react';
import { motion } from 'framer-motion';
import BlogPostCard from './BlogPostCard';
import { posts } from './BlogPostData';

export default function Blog() {
    React.useEffect(() => window.scrollTo(0,0), []) // go to top on first load
    function layOutPosts(post, idx) {
        // let yArray = new Array(idx + 2).fill(-100)
        // yArray[yArray.length - 1] = 0
        // let opacityArray = new Array(idx + 2).fill(0)
        // opacityArray[opacityArray.length] = 1
        return <div key={idx} className="col-sm-6 col-md-4 col-lg-3">
            {/* <motion.div
                animate={{ y: yArray, opacity: opacityArray }}
                transition={{ duration: 0.5 * (idx + 1), ease: "linear" }}> */}
                <BlogPostCard
                    imgSrc={post.imgSrc}
                    title={post.title}
                    date={post.date}
                    blurb={post.blurb}
                    content={post.content}
                    links={post.links}
                    modalId={post.modalId} />
            {/* </motion.div> */}
        </div>
    }

    return (
        <>
            {/* Posts */}
            <section id="posts" className="px-5 pb-5 bg-dark">
                <div className="container">
                    <motion.div
                        animate={{ x: [-100, 0], opacity: [0, 1] }}
                        transition={{ duration: 0.5, ease: "linear" }}>        
                        <h1 className="text-center text-white">Posts</h1>
                        <hr className="bg-light" />
                    </motion.div>
                    <motion.div
                        animate={{ opacity: [0, 0, 0, 1] }}
                        transition={{ duration: 1, ease: "linear" }}>        
                        <div className="row g-4 justify-content-center">
                        {/* replace the line above with the one below for horizontal scrilling */}
                        {/* <div className="row g-4 custom-scrollbar flex-nowrap overflow-y-hidden pb-3"> */}
                                {posts.map(layOutPosts)}
                        </div>
                    </motion.div>
                </div>
            </section>
        </>
    )
}
