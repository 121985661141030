import React from 'react';

export default function Resume() {
    React.useEffect(() => window.scrollTo(0, 0), []) // go to top on page load

    return (
        <>
            <section id="resume" className="bg-dark text-light p-5">
                <iframe className="container d-flex vh-100 d-none d-sm-block" src="./assets/Ophir_Gal_online_resume.pdf" title="Ophir_Gal_online_resume.pdf" />
                <div className="d-flex justify-content-center align-items-center vh-50 d-block d-sm-none">
                    <div className="card bg-dark text-white vw-75">
                        <iframe className="card-img vh-50" src="/assets/Ophir_Gal_online_resume.pdf" title="Ophir_Gal_online_resume.pdf" />
                        <div className="card-img-overlay bg-white bg-opacity-75 container d-flex justify-content-center align-items-center">
                            <a href="assets/Ophir_Gal_online_resume.pdf" target="_blank"
                                className="fs-5 fw-bold text-black">
                                <i className="px-2 fs-1 bi bi-download" />Resume PDF
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
