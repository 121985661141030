import React from 'react';

export default function ProjectCard(props) {
    return (
        <>
            <div className="card project-card">
                <a className="card-body text-center p-0 btn" data-bs-toggle="modal" data-bs-target={"#" + props.modalId}>
                    <div className="img-hover-zoom m-1 rounded">
                        <img src={props.imgSrc} className="w-100" alt="project thumbnail" />
                    </div>
                    <div className="align-items-center justify-content-center p-2">
                        <h4 className="card-text m-0 fw-bold">{props.title}</h4>
                        <h6 className="card-text text-muted">{props.date}</h6>
                        <h5 className="card-text m-sm-0">{props.blurb}</h5>
                        <h5 className="card-text m-0 fw-bold d-sm-none d-block"><i className="bi bi-link-45deg me-1"></i>Read More</h5>
                    </div>
                </a>
            </div>

            {/* "More about me." Modal */}
            <div className="modal fade" id={props.modalId} tabIndex="-1" aria-labelledby="label" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-fullscreen">
                    <div className="modal-content pe-2">
                        <div className="modal-header">
                            <div className="modal-title" id="label">
                                <h2 className="mb-0">{props.title}</h2>
                                <h4 className="text-muted">{props.date}</h4>
                            </div>
                            <button type="button" className="btn-close btn-close-white shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body d-flex justify-content-center custom-scrollbar my-2">
                            <div className="row">
                                <div className="col order-md-2 d-flex justify-content-center align-items-top mb-3">
                                    <img className="img-thumbnail bg-secondary rounded w-100" src={props.imgSrc} alt="" />
                                </div>
                                <div className="col-md-6 order-md-1">
                                    <p className="lead">{props.description}</p>
                                    <div>
                                        {props.links ? <h5 className="mb-1">Links</h5> : null}
                                        {props.links ? props.links.map((link, idx) => <a key={idx} href={link.url} target="_blank" className="btn btn-outline-light m-2 fs-5">{link.title}</a>) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
